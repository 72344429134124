import { IApp } from "@/interfaces";
import {
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING,
  HANDLE_HYGEN_ICE,
  HYGEN_SESSION_ID,
  HYGEN_SESSION_INFO,
  INIT_DIGITAL_HUMAN,
  INIT_HYGEN_SESSION,
  INIT_HYGEN_SESSION_ERROR,
  SESSION_INITIALIZED_SUCCESSFULLY,
  SPEAK_HYGEN_BOT,
  START_HYGEN_SESSION,
  STOP_HYGEN_SESSION
} from "@/store/modules/digital_human//constants";
import digital_human_setting from "@/utils/digital_human";
import { Commit } from "vuex";
import {
  InitHygenSessionPayload,
  StartHygenSessionPayload,
  SpeakHygenBotPayload,
  HandleHygenIcePayload,
  StopHygenSessionPayload
} from "./interfaces";
import {
  handle_hygen_ice,
  init_hygen_session,
  speak_hygen_bot,
  start_hygen_session,
  stop_hygen_session
} from "@/store/apis/hygen";

export default {
  [INIT_DIGITAL_HUMAN]: ({ commit }: { commit: Commit }): void => {
    const portal = process.env.VUE_APP_PORTAL ?? IApp.Portals.HCMS;
    const settings = digital_human_setting[portal];
    commit(DIGITAL_HUMAN_SETTING, settings);
  },
  [INIT_HYGEN_SESSION]: async (
    { commit }: { commit: Commit },
    payload: InitHygenSessionPayload
  ): Promise<any | null> => {
    try {
      commit(HYGEN_SESSION_ID, null);
      commit(HYGEN_SESSION_INFO, null);
      commit(INIT_HYGEN_SESSION_ERROR, null);
      commit(DIGITAL_HUMAN_LOADING, true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await init_hygen_session(payload);
      commit(HYGEN_SESSION_ID, response?.data?.data?.session_id);
      sessionStorage.setItem(
        "hygen_session_id",
        response?.data?.data?.session_id
      );
      commit(DIGITAL_HUMAN_LOADING, false);
      return response.data.data;
    } catch (e) {
      commit(DIGITAL_HUMAN_LOADING, false);
      // commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [START_HYGEN_SESSION]: async (
    { commit }: { commit: Commit },
    payload: StartHygenSessionPayload
  ): Promise<any | null> => {
    try {
      commit(INIT_HYGEN_SESSION_ERROR, null);
      commit(DIGITAL_HUMAN_LOADING, true);
      await start_hygen_session(payload);
      commit(DIGITAL_HUMAN_LOADING, false);
      commit(SESSION_INITIALIZED_SUCCESSFULLY, true);
      return true;
    } catch (e) {
      commit(DIGITAL_HUMAN_LOADING, false);
      // commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [SPEAK_HYGEN_BOT]: async (
    { commit }: { commit: Commit },
    payload: SpeakHygenBotPayload
  ): Promise<any | null> => {
    try {
      const response = await speak_hygen_bot(payload);
      return response.data.data;
    } catch (e) {
      // commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [HANDLE_HYGEN_ICE]: async (
    { commit }: { commit: Commit },
    payload: HandleHygenIcePayload
  ): Promise<any | null> => {
    try {
      const response = await handle_hygen_ice(payload);
      return response.data.data;
    } catch (e) {
      // commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [STOP_HYGEN_SESSION]: async (
    { commit }: { commit: Commit },
    payload: StopHygenSessionPayload
  ): Promise<any | null> => {
    try {
      const response = await stop_hygen_session(payload);
      return response.data.data;
    } catch (e) {
      // commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  }
};
